/* eslint-disable new-cap */
/* eslint-disable func-names */
/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-sequences */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
import Emitter from "component-emitter";
import after from "after";
import querystring from "component-querystring";
import merge from "lodash.merge";
import utm from "@segment/utm-params";
import {
  generateUUID,
  handleError,
  getDefaultPageProperties,
  getUserProvidedConfigUrl,
  checkReservedKeywords,
  getReferrer,
  getReferringDomain,
  nonutm
} from "./utils/utils";
import { CONFIG_URL } from "./utils/constants";

import FilumElementBuilder from "./utils/FilumElementBuilder";
import Storage from "./utils/storage";
import { EventRepository } from "./utils/EventRepository";
import logger from "./utils/logUtil";
import { addDomEventHandlers } from "./utils/autotrack.js";

import parseLinker from "./utils/linker";

const queryDefaults = {
  trait: "ajs_trait_",
  prop: "ajs_prop_",
};

/**
 * Add the Filum element object to flush queue
 *
 * @param {FilumElement} filumElement
 */
function enqueue(filumElement, type) {
  if (!this.eventRepository) {
    this.eventRepository = EventRepository;
  }
  this.eventRepository.enqueue(filumElement, type);
}

/**
 * class responsible for handling core
 * event tracking functionalities
 */
class Analytics {
  /**
   * Creates an instance of Analytics.
   * @memberof Analytics
   */
  constructor() {
    this.autoTrackHandlersRegistered = false;
    this.autoTrackFeatureEnabled = false;
    this.initialized = false;
    this.areEventsReplayed = false;
    this.trackValues = [];
    this.eventsBuffer = [];
    this.toBeProcessedArray = [];
    this.storage = Storage;
    this.eventRepository = EventRepository;
    this.sendAdblockPage = false;
    this.sendAdblockPageOptions = {};
    this.clientSuppliedCallbacks = {};
    this.readyCallback = () => {};
    this.executeReadyCallback = undefined;
    this.methodToCallbackMapping = {
      syncPixel: "syncPixelCallback",
    };
    this.loaded = false;
  }

  /**
   * initialize the user after load config
   */
  initializeUser() {
    this.userId =
      this.storage.getUserId() != undefined ? this.storage.getUserId() : "";

    this.userTraits =
      this.storage.getUserTraits() != undefined
        ? this.storage.getUserTraits()
        : {};

    this.groupId =
      this.storage.getGroupId() != undefined ? this.storage.getGroupId() : "";

    this.groupTraits =
      this.storage.getGroupTraits() != undefined
        ? this.storage.getGroupTraits()
        : {};

    this.anonymousId = this.getAnonymousId();

    // save once for storing older values to encrypted
    this.storage.setUserId(this.userId);
    this.storage.setAnonymousId(this.anonymousId);
    this.storage.setGroupId(this.groupId);
    this.storage.setUserTraits(this.userTraits);
    this.storage.setGroupTraits(this.groupTraits);
  }

  setInitialPageProperties() {
    let initialReferrer = this.storage.getInitialReferrer();
    let initialReferringDomain = this.storage.getInitialReferringDomain();
    if (initialReferrer == null && initialReferringDomain == null) {
      initialReferrer = getReferrer();
      initialReferringDomain = getReferringDomain(initialReferrer);
      this.storage.setInitialReferrer(initialReferrer);
      this.storage.setInitialReferringDomain(initialReferringDomain);
    }
  }

  pause(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  /**
   * Process page params and forward to page call
   *
   * @param {*} category
   * @param {*} name
   * @param {*} properties
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  page(category, name, properties, options, callback) {
    if (!this.loaded) return;
    if (typeof options === "function") (callback = options), (options = null);
    if (typeof properties === "function")
      (callback = properties), (options = properties = null);
    if (typeof name === "function")
      (callback = name), (options = properties = name = null);
    if (
      typeof category === "object" &&
      category != null &&
      category != undefined
    )
      (options = name), (properties = category), (name = category = null);
    if (typeof name === "object" && name != null && name != undefined)
      (options = properties), (properties = name), (name = null);
    if (typeof category === "string" && typeof name !== "string")
      (name = category), (category = null);
    if (this.sendAdblockPage && category != "FilumAnalyticsJS-Initiated") {
      this.sendSampleRequest();
    }
    this.processPage(category, name, properties, options, callback);
  }

  /**
   * Process track params and forward to track call
   *
   * @param {*} event
   * @param {*} properties
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  track(event, properties, options, callback) {
    if (!this.loaded) return;
    if (typeof options === "function") (callback = options), (options = null);
    if (typeof properties === "function")
      (callback = properties), (options = null), (properties = null);

    this.processTrack(event, properties, options, callback);
  }

  /**
   * Process identify params and forward to indentify  call
   *
   * @param {*} userId
   * @param {*} traits
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  identify(userId, traits, options, callback) {
    if (!this.loaded) return;
    if (typeof options === "function") (callback = options), (options = null);
    if (typeof traits === "function")
      (callback = traits), (options = null), (traits = null);
    if (typeof userId === "object")
      (options = traits), (traits = userId), (userId = this.userId);

    this.processIdentify(userId, traits, options, callback);
  }

  /**
   *
   * @param {*} to
   * @param {*} from
   * @param {*} options
   * @param {*} callback
   */
  alias(to, from, options, callback) {
    if (!this.loaded) return;
    if (typeof options === "function") (callback = options), (options = null);
    if (typeof from === "function")
      (callback = from), (options = null), (from = null);
    if (typeof from === "object") (options = from), (from = null);

    const filumElement = new FilumElementBuilder().setType("alias").build();
    filumElement.message.previousId =
      from || (this.userId ? this.userId : this.getAnonymousId());
    filumElement.message.userId = to;

    this.processAndSendDataToDestinations(
      "alias",
      filumElement,
      options,
      callback
    );
  }

  /**
   *
   * @param {*} to
   * @param {*} from
   * @param {*} options
   * @param {*} callback
   */
  group(groupId, traits, options, callback) {
    if (!this.loaded) return;
    if (!arguments.length) return;

    if (typeof options === "function") (callback = options), (options = null);
    if (typeof traits === "function")
      (callback = traits), (options = null), (traits = null);
    if (typeof groupId === "object")
      (options = traits), (traits = groupId), (groupId = this.groupId);

    this.groupId = groupId;
    this.storage.setGroupId(this.groupId);

    const filumElement = new FilumElementBuilder().setType("group").build();
    if (traits) {
      for (const key in traits) {
        this.groupTraits[key] = traits[key];
      }
    } else {
      this.groupTraits = {};
    }
    this.storage.setGroupTraits(this.groupTraits);

    this.processAndSendDataToDestinations(
      "group",
      filumElement,
      options,
      callback
    );
  }

  /**
   * Send page call to Filum
   *
   * @param {*} category
   * @param {*} name
   * @param {*} properties
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  processPage(category, name, properties, options, callback) {
    const filumElement = new FilumElementBuilder().setType("page").build();
    if (!properties) {
      properties = {};
    }
    if (name) {
      filumElement.message.name = name;
      properties.name = name;
    }
    if (category) {
      filumElement.message.category = category;
      properties.category = category;
    }
    filumElement.message.properties = this.getPageProperties(properties); // properties;

    this.trackPage(filumElement, options, callback);
  }

  /**
   * Send track call to Filum
   *
   * @param {*} event
   * @param {*} properties
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  processTrack(event, properties, options, callback) {
    const filumElement = new FilumElementBuilder().setType("track").build();
    if (event) {
      filumElement.setEventName(event);
    }
    if (properties) {
      filumElement.setProperty(properties);
    } else {
      filumElement.setProperty({});
    }
    this.trackEvent(filumElement, options, callback);
  }

  /**
   * Send identify call to Filum
   *
   * @param {*} userId
   * @param {*} traits
   * @param {*} options
   * @param {*} callback
   * @memberof Analytics
   */
  processIdentify(userId, traits, options, callback) {
    if (userId && this.userId && userId !== this.userId) {
      this.reset();
    }
    this.userId = userId;
    this.storage.setUserId(this.userId);

    if (traits) {
      for (const key in traits) {
        this.userTraits[key] = traits[key];
      }
      this.storage.setUserTraits(this.userTraits);
    }

    const filumElement = new FilumElementBuilder()
      .setType("identify")
      .setEvent("Identify")
      .setUserId(this.userId)
      .setProperty(this.userTraits)
      .build();
    this.identifyUser(filumElement, options, callback);
  }

  /**
   * Identify call supporting filum element from builder
   *
   * @param {*} filumElement
   * @param {*} callback
   * @memberof Analytics
   */
  identifyUser(filumElement, options, callback) {
    if (filumElement.message.userId) {
      this.userId = filumElement.message.userId;
      this.storage.setUserId(this.userId);
    }

    if (
      filumElement &&
      filumElement.message &&
      filumElement.message.context &&
      filumElement.message.context.traits
    ) {
      this.userTraits = {
        ...filumElement.message.context.traits,
      };
      this.storage.setUserTraits(this.userTraits);
    }

    this.processAndSendDataToDestinations(
      "identify",
      filumElement,
      options,
      callback
    );
  }

  /**
   * Page call supporting filum element from builder
   *
   * @param {*} filumElement
   * @param {*} callback
   * @memberof Analytics
   */
  trackPage(filumElement, options, callback) {
    this.processAndSendDataToDestinations(
      "page",
      filumElement,
      options,
      callback
    );
  }

  /**
   * Track call supporting filum element from builder
   *
   * @param {*} filumElement
   * @param {*} callback
   * @memberof Analytics
   */
  trackEvent(filumElement, options, callback) {
    this.processAndSendDataToDestinations(
      "track",
      filumElement,
      options,
      callback
    );
  }

  /**
   * Process and send data to destinations along with Filum
   *
   * @param {*} type
   * @param {*} filumElement
   * @param {*} callback
   * @memberof Analytics
   */
  processAndSendDataToDestinations(type, filumElement, options, callback) {
    try {
      if (!this.anonymousId) {
        this.setAnonymousId();
      }

      // assign page properties to context
      // filumElement.message.context.page = getDefaultPageProperties();

      filumElement.message.context.traits = {
        ...this.userTraits,
      };

      logger.debug("anonymousId: ", this.anonymousId);
      filumElement.message.anonymousId = this.anonymousId;
      filumElement.message.userId = filumElement.message.userId
        ? filumElement.message.userId
        : this.userId;

      if (type == "group") {
        if (this.groupId) {
          filumElement.message.groupId = this.groupId;
        }
        if (this.groupTraits) {
          filumElement.message.traits = {
            ...this.groupTraits,
          };
        }
      }

      this.processOptionsParam(filumElement, options);
      logger.debug(JSON.stringify(filumElement));

      // check for reserved keys and log
      checkReservedKeywords(filumElement.message, type);

      // self analytics process, send to filum
      enqueue.call(this, filumElement, type);

      logger.debug(`${type} is called `);
      if (callback) {
        callback();
      }
    } catch (error) {
      handleError(error);
    }
  }

  /**
   * add campaign parsed details under context
   * @param {*} filumElement
   */
  addCampaignInfo(filumElement) {
    const { search } = getDefaultPageProperties();
    // use utm.strict to get only the default 5 utm params
    const campaign = utm.strict(search);
    if (
      filumElement.message.context &&
      typeof filumElement.message.context === "object"
    ) {
      filumElement.message.context.campaign = campaign;
    }
  }

  /**
   * add non-UTM params parsed under event params if the event is Identify
   * @param {*} filumElement
   */
  addNonUTMParams(filumElement) {
    const { search } = getDefaultPageProperties();
    const non_utm_params = nonutm(search);

    if (
      filumElement.message.properties &&
      typeof filumElement.message.properties === "object"
    ) {
      for (var key in non_utm_params) {
        filumElement.message.properties[key] = non_utm_params[key];
      }
    }
  }

  /**
   * process options parameter
   * Apart from top level keys merge everyting under context
   * context.page's default properties are overriden by same keys of
   * provided properties in case of page call
   *
   * @param {*} filumElement
   * @param {*} options
   * @memberof Analytics
   */
  processOptionsParam(filumElement, options) {
    const { type, properties } = filumElement.message;

    this.addCampaignInfo(filumElement);

    // check if identify then appends non-UTM params into event params
    if (filumElement.message.type === "identify") {
      this.addNonUTMParams(filumElement);
    }

    // assign page properties to context.page
    filumElement.message.context.page =
      type == "page"
        ? this.getContextPageProperties(properties)
        : this.getContextPageProperties();

    const toplevelElements = ["anonymousId", "originalTimestamp"];
    for (const key in options) {
      if (toplevelElements.includes(key)) {
        filumElement.message[key] = options[key];
      } else if (key !== "context") {
        filumElement.message.context = merge(filumElement.message.context, {
          [key]: options[key],
        });
      } else if (typeof options[key] === "object" && options[key] != null) {
        filumElement.message.context = merge(filumElement.message.context, {
          ...options[key],
        });
      } else {
        logger.error(
          "[Analytics: processOptionsParam] context passed in options is not object"
        );
      }
    }
  }

  getPageProperties(properties, options) {
    const defaultPageProperties = getDefaultPageProperties();
    const optionPageProperties = options && options.page ? options.page : {};
    defaultPageProperties.initial_referrer = this.storage.getInitialReferrer();
    defaultPageProperties.initial_referring_domain = this.storage.getInitialReferringDomain();
    for (const key in defaultPageProperties) {
      if (properties[key] === undefined) {
        properties[key] =
          optionPageProperties[key] || defaultPageProperties[key];
      }
    }
    return properties;
  }

  // Assign page properties to context.page if the same property is not provided under context.page
  getContextPageProperties(properties) {
    const defaultPageProperties = getDefaultPageProperties();
    const contextPageProperties = {};
    for (const key in defaultPageProperties) {
      contextPageProperties[key] =
        properties && properties[key]
          ? properties[key]
          : defaultPageProperties[key];
    }
    return contextPageProperties;
  }

  /**
   * Clear user information
   *
   * @memberof Analytics
   */
  reset() {
    if (!this.loaded) return;
    this.userId = "";
    this.userTraits = {};
    this.groupId = "";
    this.groupTraits = {};
    this.storage.clear();
  }

  getAnonymousId() {
    // if (!this.loaded) return;
    this.anonymousId = this.storage.getAnonymousId();
    if (!this.anonymousId) {
      this.setAnonymousId();
    }
    return this.anonymousId;
  }

  /**
   * Sets anonymous id in the followin precedence:
   * 1. anonymousId: Id directly provided to the function.
   * 2. filumAmpLinkerParm: value generated from linker query parm (filum stack)
   *    using praseLinker util.
   * 3. generateUUID: A new uniquie id is generated and assigned.
   *
   * @param {string} anonymousId
   * @param {string} filumAmpLinkerParm
   */
  setAnonymousId(anonymousId, filumAmpLinkerParm) {
    // if (!this.loaded) return;
    const parsedAnonymousIdObj = filumAmpLinkerParm
      ? parseLinker(filumAmpLinkerParm)
      : null;
    const parsedAnonymousId = parsedAnonymousIdObj
      ? parsedAnonymousIdObj.rs_amp_id
      : null;
    this.anonymousId = anonymousId || parsedAnonymousId || generateUUID();
    this.storage.setAnonymousId(this.anonymousId);
  }

  isValidWriteKey(writeKey) {
    if (
      !writeKey ||
      typeof writeKey !== "string" ||
      writeKey.trim().length == 0
    ) {
      return false;
    }
    return true;
  }

  isValidServerUrl(serverUrl) {
    if (
      !serverUrl ||
      typeof serverUrl !== "string" ||
      serverUrl.trim().length == 0
    ) {
      return false;
    }
    return true;
  }

  /**
   * Call control pane to get client configs
   *
   * @param {*} writeKey
   * @memberof Analytics
   */
  load(writeKey, serverUrl, options) {
    logger.debug("inside load ");
    if (this.loaded) return;
    if (!this.isValidWriteKey(writeKey)) {
      handleError({
        message:
          "[Analytics] load:: Unable to load due to wrong writeKey or serverUrl",
      });
      throw Error("failed to initialize");
    }
    if (options && options.logLevel) {
      logger.setLogLevel(options.logLevel);
    }
    if (options && options.setCookieDomain) {
      this.storage.options({ domain: options.setCookieDomain });
    }
    if (options && options.configUrl) {
      configUrl = getUserProvidedConfigUrl(options.configUrl);
    }
    if (options && options.sendAdblockPage) {
      this.sendAdblockPage = true;
    }
    if (options && options.sendAdblockPageOptions) {
      if (typeof options.sendAdblockPageOptions === "object") {
        this.sendAdblockPageOptions = options.sendAdblockPageOptions;
      }
    }
    if (options && options.clientSuppliedCallbacks) {
      // convert to filum recognised method names
      const tranformedCallbackMapping = {};
      Object.keys(this.methodToCallbackMapping).forEach((methodName) => {
        if (this.methodToCallbackMapping.hasOwnProperty(methodName)) {
          if (
            options.clientSuppliedCallbacks[
              this.methodToCallbackMapping[methodName]
            ]
          ) {
            tranformedCallbackMapping[methodName] =
              options.clientSuppliedCallbacks[
                this.methodToCallbackMapping[methodName]
              ];
          }
        }
      });
      Object.assign(this.clientSuppliedCallbacks, tranformedCallbackMapping);
      this.registerCallbacks(true);
    }

    if (
      options &&
      options.queueOptions &&
      options.queueOptions != null &&
      typeof options.queueOptions == "object"
    ) {
      this.eventRepository.startQueue(options.queueOptions);
    } else {
      this.eventRepository.startQueue({});
    }

    this.eventRepository.writeKey = writeKey;
    if (serverUrl) {
      this.eventRepository.url = serverUrl;
    }

    const hasAnonymousID = this.storage.getAnonymousId() != undefined;

    this.initializeUser();
    this.setInitialPageProperties();
    this.loaded = true;

    // send identify when no anonymousID or any utm/non default utm params exists in the URL
    const { search } = getDefaultPageProperties();
    const campaign = utm.strict(search);
    const nonDefaultUtmParams = nonutm(search);

    if (
      !hasAnonymousID ||
      (campaign && Object.keys(campaign).length != 0) ||
      (nonDefaultUtmParams && Object.keys(nonDefaultUtmParams).length != 0)
    ) {
      this.identify({});
    }

    if (
      options &&
      options.valTrackingList &&
      options.valTrackingList.push == Array.prototype.push
    ) {
      this.trackValues = options.valTrackingList;
    }
    if (options && options.useAutoTracking) {
      this.autoTrackFeatureEnabled = true;
      if (this.autoTrackFeatureEnabled && !this.autoTrackHandlersRegistered) {
        addDomEventHandlers(this);
        this.autoTrackHandlersRegistered = true;
        logger.debug(
          "autoTrackHandlersRegistered",
          this.autoTrackHandlersRegistered
        );
      }
    }

    function errorHandler(error) {
      handleError(error);
      if (this.autoTrackFeatureEnabled && !this.autoTrackHandlersRegistered) {
        addDomEventHandlers(this);
      }
    }

    processDataInAnalyticsArray(this);
  }

  ready(callback) {
    if (!this.loaded) return;
    if (typeof callback === "function") {
      this.readyCallback = callback;
      return;
    }
    logger.error("ready callback is not a function");
  }

  initializeCallbacks() {
    Object.keys(this.methodToCallbackMapping).forEach((methodName) => {
      if (this.methodToCallbackMapping.hasOwnProperty(methodName)) {
        this.on(methodName, () => {});
      }
    });
  }

  registerCallbacks(calledFromLoad) {
    if (!calledFromLoad) {
      Object.keys(this.methodToCallbackMapping).forEach((methodName) => {
        if (this.methodToCallbackMapping.hasOwnProperty(methodName)) {
          if (window.filumanalytics) {
            if (
              typeof window.filumanalytics[
                this.methodToCallbackMapping[methodName]
              ] === "function"
            ) {
              this.clientSuppliedCallbacks[methodName] =
                window.filumanalytics[this.methodToCallbackMapping[methodName]];
            }
          }
        }
      });
    }

    Object.keys(this.clientSuppliedCallbacks).forEach((methodName) => {
      if (this.clientSuppliedCallbacks.hasOwnProperty(methodName)) {
        logger.debug(
          "registerCallbacks",
          methodName,
          this.clientSuppliedCallbacks[methodName]
        );
        this.on(methodName, this.clientSuppliedCallbacks[methodName]);
      }
    });
  }

  sendSampleRequest() {
    ScriptLoader(
      "ad-block",
      "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    );
  }

  /**
   * parse the given query string into usable Filum object
   * @param {*} query
   */
  parseQueryString(query) {
    function getTraitsFromQueryObject(qObj) {
      const traits = {};
      Object.keys(qObj).forEach((key) => {
        if (key.substr(0, queryDefaults.trait.length) == queryDefaults.trait) {
          traits[key.substr(queryDefaults.trait.length)] = qObj[key];
        }
      });

      return traits;
    }

    function getEventPropertiesFromQueryObject(qObj) {
      const props = {};
      Object.keys(qObj).forEach((key) => {
        if (key.substr(0, queryDefaults.prop.length) == queryDefaults.prop) {
          props[key.substr(queryDefaults.prop.length)] = qObj[key];
        }
      });

      return props;
    }

    const returnObj = {};
    const queryObject = querystring.parse(query);
    const userTraits = getTraitsFromQueryObject(queryObject);
    const eventProps = getEventPropertiesFromQueryObject(queryObject);
    if (queryObject.ajs_uid) {
      returnObj.userId = queryObject.ajs_uid;
      returnObj.traits = userTraits;
    }
    if (queryObject.ajs_aid) {
      returnObj.anonymousId = queryObject.ajs_aid;
    }
    if (queryObject.ajs_event) {
      returnObj.event = queryObject.ajs_event;
      returnObj.properties = eventProps;
    }

    return returnObj;
  }
}

function pushQueryStringDataToAnalyticsArray(obj) {
  if (obj.anonymousId) {
    if (obj.userId) {
      instance.toBeProcessedArray.push(
        ["setAnonymousId", obj.anonymousId],
        ["identify", obj.userId, obj.traits]
      );
    } else {
      instance.toBeProcessedArray.push(["setAnonymousId", obj.anonymousId]);
    }
  } else if (obj.userId) {
    instance.toBeProcessedArray.push(["identify", obj.userId, obj.traits]);
  }

  if (obj.event) {
    instance.toBeProcessedArray.push(["track", obj.event, obj.properties]);
  }
}

function processDataInAnalyticsArray(analytics) {
  if (instance.loaded) {
    for (let i = 0; i < analytics.toBeProcessedArray.length; i++) {
      const event = [...analytics.toBeProcessedArray[i]];
      const method = event[0];
      event.shift();
      logger.debug("=====from analytics array, calling method:: ", method);
      analytics[method](...event);
    }

    instance.toBeProcessedArray = [];
  }
}

const instance = new Analytics();

Emitter(instance);

window.addEventListener(
  "error",
  (e) => {
    handleError(e, instance);
  },
  true
);

// if (process.browser) {
// test for adblocker
// instance.sendSampleRequest()

// initialize supported callbacks
instance.initializeCallbacks();

// register supported callbacks
instance.registerCallbacks(false);
const eventsPushedAlready =
  !!window.filumanalytics && window.filumanalytics.push == Array.prototype.push;

const argumentsArray = window.filumanalytics;

while (argumentsArray && argumentsArray[0] && argumentsArray[0][0] !== "load") {
  argumentsArray.shift();
}
if (
  argumentsArray &&
  argumentsArray.length > 0 &&
  argumentsArray[0][0] === "load"
) {
  const method = argumentsArray[0][0];
  argumentsArray[0].shift();
  logger.debug("=====from init, calling method:: ", method);
  instance[method](...argumentsArray[0]);
  argumentsArray.shift();
}

// once loaded, parse querystring of the page url to send events
const parsedQueryObject = instance.parseQueryString(window.location.search);

pushQueryStringDataToAnalyticsArray(parsedQueryObject);

if (argumentsArray && argumentsArray.length > 0) {
  for (let i = 0; i < argumentsArray.length; i++) {
    instance.toBeProcessedArray.push(argumentsArray[i]);
  }
}
if (eventsPushedAlready) {
  processDataInAnalyticsArray(instance);
}
// }

const ready = instance.ready.bind(instance);
const identify = instance.identify.bind(instance);
const page = instance.page.bind(instance);
const track = instance.track.bind(instance);
const alias = instance.alias.bind(instance);
const group = instance.group.bind(instance);
const reset = instance.reset.bind(instance);
const load = instance.load.bind(instance);
const initialized = (instance.initialized = true);
const getAnonymousId = instance.getAnonymousId.bind(instance);
const setAnonymousId = instance.setAnonymousId.bind(instance);

export {
  initialized,
  ready,
  page,
  track,
  load,
  identify,
  reset,
  alias,
  group,
  getAnonymousId,
  setAnonymousId,
};
