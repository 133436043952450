// Library information class
class ContextLibrary {
  constructor() {
    this.name = "Filum AnalyticsJS";
    this.version = "process.package_version";
  }
}
// Operating System information class
class ContextOS {
  constructor() {
    this.name = "";
    this.version = "";
  }
}
// Screen information class
class ContextScreen {
  constructor() {
    this.density = -1;
    this.width = -1;
    this.height = -1;
  }
}
// Device information class
class ContextDevice {
  constructor() {
    this.id = "";
    this.advertising_id = "";
    this.manufacturer = "";
    this.model = "";
    this.name = "";
    this.type = "";
    this.version = "";
  }
}

class ContextNetwork {
  constructor() {
    this.bluetooth = 0;
    this.carrier = "";
    this.cellular = 0;
    this.wifi = 0;
  }
}

class ContextLocation {
  constructor() {
    this.city = "";
    this.country = "";
    this.latitude = -1;
    this.longitude = -1;
    this.region = "";
    this.speed = 0;
  }
}

export {
  ContextLibrary,
  ContextOS,
  ContextScreen,
  ContextDevice,
  ContextNetwork,
  ContextLocation
};
