import Queue from "@segment/localstorage-retry";
import {
  BASE_URL,
  FLUSH_QUEUE_SIZE,
  FLUSH_INTERVAL_DEFAULT,
} from "./constants";
import { getCurrentTimeFormatted, handleError, replacer, convert_to_filum_event_format, convert_dict_to_filum_dict} from "./utils";

import { FilumPayload as FilumPayload } from "./FilumPayload";
import logger from "./logUtil";
// import * as XMLHttpRequestNode from "Xmlhttprequest";

let XMLHttpRequestNode;
if (!process.browser) {
  XMLHttpRequestNode = require("Xmlhttprequest");
}

let btoaNode;
if (!process.browser) {
  btoaNode = require("btoa");
}

const queueOptions = {
  maxRetryDelay: 360000,
  minRetryDelay: 1000,
  backoffFactor: 2,
  maxAttempts: 10,
  maxItems: 100,
};

const MESSAGE_LENGTH = 32 * 1000; // ~32 Kb

/**
 *
 * @class EventRepository responsible for adding events into
 * flush queue and sending data to Filum
 * in batch and maintains order of the event.
 */
class EventRepository {
  /**
   *Creates an instance of EventRepository.
   * @memberof EventRepository
   */
  constructor(options) {
    this.eventsBuffer = [];
    this.writeKey = "";
    this.url = "";
    this.state = "READY";
    this.batchSize = 0;

    // previous implementation
    // setInterval(this.preaparePayloadAndFlush, FLUSH_INTERVAL_DEFAULT, this);
  }

  startQueue(options) {
    if (options) {
      // TODO: add checks for value - has to be +ve?
      Object.assign(queueOptions, options);
    }
    this.payloadQueue = new Queue("filum", queueOptions, function (
      item,
      done
    ) {
      // apply sent_at at flush time and reset on each retry
      item.message.sent_at = getCurrentTimeFormatted();
      // send this item for processing, with a callback to enable queue to get the done status
      eventRepository.processQueueElement(
        item.url,
        item.headers,
        item.message,
        10 * 1000,
        function (err, res) {
          if (err) {
            return done(err);
          }
          done(null, res);
        }
      );
    });

    // start queue
    this.payloadQueue.start();
  }

  /**
   *
   *
   * @param {EventRepository} repo
   * @returns
   * @memberof EventRepository
   */
  preaparePayloadAndFlush(repo) {
    // construct payload
    logger.debug(`==== in preaparePayloadAndFlush with state: ${repo.state}`);
    logger.debug(repo.eventsBuffer);
    if (repo.eventsBuffer.length == 0 || repo.state === "PROCESSING") {
      return;
    }
    const eventsPayload = repo.eventsBuffer;
    const payload = new FilumPayload();
    payload.batch = eventsPayload;
    payload.writeKey = repo.writeKey;
    payload.sent_at = getCurrentTimeFormatted();

    // add sent_at to individual events as well
    payload.batch.forEach((event) => {
      event.sent_at = payload.sent_at;
    });

    repo.batchSize = repo.eventsBuffer.length;
    // server-side integration, XHR is node module

    if (process.browser) {
      var xhr = new XMLHttpRequest();
    } else {
      var xhr = new XMLHttpRequestNode.XMLHttpRequest();
    }

    logger.debug("==== in flush sending to Filum ====");
    logger.debug(JSON.stringify(payload));

    xhr.open("POST", repo.url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    if (process.browser) {
      xhr.setRequestHeader("Authorization", `Bearer ${payload.writeKey}`);
    } else {
      xhr.setRequestHeader("Authorization", `Bearer ${payload.writeKey}`);
    }

    // register call back to reset event buffer on successfull POST
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        logger.debug(`====== request processed successfully: ${xhr.status}`);
        repo.eventsBuffer = repo.eventsBuffer.slice(repo.batchSize);
        logger.debug(repo.eventsBuffer.length);
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        handleError(
          new Error(
            `request failed with status: ${xhr.status} for url: ${repo.url}`
          )
        );
      }
      repo.state = "READY";
    };
    // xhr.send(JSON.stringify(payload, replacer));
    xhr.send(JSON.stringify(payload.batch));
    repo.state = "PROCESSING";
  }

  /**
   * the queue item processor
   * @param {*} url to send requests to
   * @param {*} headers
   * @param {*} message
   * @param {*} timeout
   * @param {*} queueFn the function to call after request completion
   */
  processQueueElement(url, headers, message, timeout, queueFn) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      for (const k in headers) {
        xhr.setRequestHeader(k, headers[k]);
      }
      xhr.timeout = timeout;
      xhr.ontimeout = queueFn;
      xhr.onerror = queueFn;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 429 || (xhr.status >= 500 && xhr.status < 600)) {
            handleError(
              new Error(
                `request failed with status: ${xhr.status}${xhr.statusText} for url: ${url}`
              )
            );
            queueFn(
              new Error(
                `request failed with status: ${xhr.status}${xhr.statusText} for url: ${url}`
              )
            );
          } else {
            logger.debug(
              `====== request processed successfully: ${xhr.status}`
            );
            queueFn(null, xhr.status);
          }
        }
      };
      
      xhr.send(JSON.stringify([message]));
    } catch (error) {
      queueFn(error);
    }
  }

  /**
   *
   *
   * @param {FilumElement} filumElement
   * @memberof EventRepository
   */
  enqueue(filumElement, type) {
    const message = filumElement.getElementContent();

    const headers = {
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${this.writeKey}`
    };

    delete message.action;
    delete message.channel;
    delete message.integrations;
    delete message.user_properties;
    
    message.original_timestamp = getCurrentTimeFormatted();
    message.timestamp = message.original_timestamp;
    delete message.originalTimestamp;
    
    message.sent_at = getCurrentTimeFormatted(); // add this, will get modified when actually being sent
    delete message.sentAt;
    message.received_at = message.sent_at;

    message.anonymous_id = message.anonymousId;
    delete message.anonymousId;

    message.user_id = message.userId;
    delete message.userId;

    message.event_name = message.event;
    delete message.event;

    message.event_type = message.type;
    delete message.type;

    message.event_id = message.messageId;
    delete message.messageId;
    
    message.origin = null;
    delete message.userProperties;
    
    message.event_params = convert_to_filum_event_format(message.properties);
    delete message.properties;

    if ("traits" in message.context){
      delete message.context.traits;
    }
    if ("referring_domain" in message.context.page){
      delete message.context.page.referring_domain;
    }
    // message.context = convert_dict_to_filum_dict(message.context);
    
    // check message size, if greater log an error
    if (JSON.stringify(message).length > MESSAGE_LENGTH) {
      logger.error(
        "[EventRepository] enqueue:: message length greater 32 Kb ",
        message
      );
    }
    // modify the url for event specific endpoints
    const url = this.url.slice(-1) == "/" ? this.url.slice(0, -1) : this.url;
    // add items to the queue
    this.payloadQueue.addItem({
      url: `${url}`,
      headers,
      message,
    });
  }
}
let eventRepository = new EventRepository();
export { eventRepository as EventRepository };
