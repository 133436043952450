import FilumProperty from "./FilumProperty"
// Class for building the Context Campaign message payload
class ContextCampaignBuilder {
  constructor() {
    this.name = "";
    this.source = "";
    this.medium = "";
    this.term = "";
    this.content = "";
  }

  build() {
    // if (!this.url || this.url.length === 0) {
    //   throw new Error("Page url cannot be null or empty");
    // }
    const campaignProperty = new FilumProperty();
    campaignProperty.setProperty("name", this.name);
    campaignProperty.setProperty("source", this.source);
    campaignProperty.setProperty("medium", this.medium);
    campaignProperty.setProperty("term", this.term);
    campaignProperty.setProperty("content", this.content);
    return campaignProperty;
  }

  // Setter methods to align with Builder pattern

  setName(name) {
    this.name = name;
    return this;
  }

  setSource(source) {
    this.source = source;
    return this;
  }

  setMedium(medium) {
    this.medium = medium;
    return this;
  }

  setTerm(term) {
    this.term = term;
    return this;
  }

  setContent(content) {
    this.content = content;
    return this;
  }
}

export {
  ContextCampaignBuilder
};
