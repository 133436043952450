// Context class
import FilumApp from "./FilumApp";
import {
  ContextLibrary,
  ContextOS,
  ContextScreen,
  ContextDevice,
  ContextNetwork,
  ContextLocation
} from "./FilumInfo";

import {
  PagePropertyBuilder
} from "./PagePropertyBuilder";

import {
  ContextCampaignBuilder
} from "./ContextCampaignBuilder";

import {
  ContextReferrerBuilder
} from "./ContextReferrerBuilder";

class FilumContext {
  constructor() {
    this.active = 1;
    this.app = new FilumApp();
    this.campaign = new ContextCampaignBuilder();
    this.device = new ContextDevice();
    this.ip = "";
    this.library = new ContextLibrary();
    this.locale = "";
    this.location = new ContextLocation();
    this.network = new ContextNetwork();
    const os = new ContextOS();
    os.version = "";
    this.page = new PagePropertyBuilder();
    this.referrer = new ContextReferrerBuilder();
    const screen = new ContextScreen();
    
    // Depending on environment within which the code is executing, screen
    // dimensions can be set
    // User agent and locale can be retrieved only for browser
    // For server-side integration, same needs to be set by calling program
    if (!process.browser) {
      // server-side integration
      screen.width = -1;
      screen.height = -1;
      screen.density = -1;
      os.version = "";
      os.name = "";
      this.user_agent = null;
      this.locale = null;
    } else {
      // running within browser
      screen.width = Number(window.screen.width);
      screen.height = Number(window.screen.height);
      screen.density = window.devicePixelRatio;
      this.user_agent = navigator.userAgent;
      // property name differs based on browser version
      this.locale = navigator.language || navigator.browserLanguage;
    }
    this.os = os;
    this.screen = screen;
  }
}
export default FilumContext;
