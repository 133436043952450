import FilumMessage from "./FilumMessage";
// Individual element class containing Filum Message
class FilumElement {
  constructor() {
    this.message = new FilumMessage();
  }

  // Setters that in turn set the field values for the contained object
  setType(type) {
    this.message.type = type;
  }

  setProperty(filumProperty) {
    this.message.properties = filumProperty;
  }

  setUserProperty(filumUserProperty) {
    this.message.user_properties = filumUserProperty;
  }

  setUserId(userId) {
    this.message.userId = userId;
  }

  setEventName(eventName) {
    this.message.event = eventName;
  }

  updateTraits(traits) {
    this.message.context.traits = traits;
  }

  getElementContent() {
    return this.message;
  }
}
export default FilumElement;
