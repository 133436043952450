// Class responsible for building up the individual elements in a batch
// that is transmitted by the SDK
import FilumElement from "./FilumElement.js";

class FilumElementBuilder {
  constructor() {
    this.filumProperty = null;
    this.filumUserProperty = null;
    this.event = null;
    this.userId = null;
    this.channel = null;
    this.type = null;
  }

  // Set the property
  setProperty(inputFilumProperty) {
    this.filumProperty = inputFilumProperty;
    return this;
  }

  // Build and set the property object
  setPropertyBuilder(filumPropertyBuilder) {
    this.filumProperty = filumPropertyBuilder.build();
    return this;
  }

  setUserProperty(inputFilumUserProperty) {
    this.filumUserProperty = inputFilumUserProperty;
    return this;
  }

  setUserPropertyBuilder(filumUserPropertyBuilder) {
    this.filumUserProperty = filumUserPropertyBuilder.build();
    return this;
  }

  // Setter methods for all variables. Instance is returned for each call in
  // accordance with the Builder pattern

  setEvent(event) {
    this.event = event;
    return this;
  }

  setUserId(userId) {
    this.userId = userId;
    return this;
  }

  setChannel(channel) {
    this.channel = channel;
    return this;
  }

  setType(eventType) {
    this.type = eventType;
    return this;
  }

  build() {
    const element = new FilumElement();
    element.setUserId(this.userId);
    element.setType(this.type);
    element.setEventName(this.event);
    element.setProperty(this.filumProperty);
    element.setUserProperty(this.filumUserProperty);
    return element;
  }
}
export default FilumElementBuilder;
