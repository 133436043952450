import FilumProperty from "./FilumProperty"
// Class for building the referrer message payload
class ContextReferrerBuilder {
  constructor() {
    this.type = "";
    this.name = "";
    this.url = "";
    this.link = "";
  }

  // Build the core constituents of the payload
  build() {
    // if (!this.url || this.url.length === 0) {
    //   throw new Error("Page url cannot be null or empty");
    // }
    const referrerProperty = new FilumProperty();
    referrerProperty.setProperty("type", this.type);
    referrerProperty.setProperty("name", this.name);
    referrerProperty.setProperty("url", this.url);
    referrerProperty.setProperty("link", this.link);
    return referrerProperty;
  }

  // Setter methods to align with Builder pattern

  setType(type) {
    this.type = type;
    return this;
  }

  setUrl(url) {
    this.url = url;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setLink(link) {
    this.link = link;
    return this;
  }
}

export {
  ContextReferrerBuilder
};
